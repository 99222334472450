<template>

  <div class="cbox">
    <div class="headbar">
      <div style="float: right;">
        <el-button size="small" icon="el-icon-plus" @click="add" type="primary">新增</el-button>
      </div>
      <div style="padding-top: 14px;">
        <el-breadcrumb>
          <el-breadcrumb-item>内容管理</el-breadcrumb-item>
          <el-breadcrumb-item>轮播图设置</el-breadcrumb-item>

        </el-breadcrumb>
      </div>

    </div>
    <div class="bcontent">


      <el-table :data="DataList" stripe border>
        <el-table-column type="index" label="编号" width="80">
        </el-table-column>
        <el-table-column prop="cover" label="封面" width="120">
          <template slot-scope="scope">
            <div style="width: 100px;  line-height: 50px; overflow: hidden;">
              <img :src="scope.row.image" style="width: 100px; vertical-align: middle;" v-viewer />
            </div>

          </template>
        </el-table-column>
        <el-table-column prop="outurl" label="跳转页面地址" min-width="200">
        </el-table-column>


        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">

            <el-button type="text" @click="update(scope.row)">编辑</el-button>
            <el-button type="text" @click="remove(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog title="编辑轮播图" :visible.sync="dialogVisible" :close-on-click-modal="false">
      <el-row>
        <el-col :span="4">
          &nbsp;
        </el-col>
        <el-col :span="20">
          <el-upload action="" :http-request="uploadOss" :on-success="uploadCover" :show-file-list="false" name="image">
            <div slot="tip" class="el-upload__tip">尺寸750:360</div>
            <img v-if="EditItem.image !== ''" :src="EditItem.image" width="125">
            <el-button v-else size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-col>
      </el-row>

      <el-form :model="EditItem" ref="EditItem" label-width="120px">

        <el-row style="padding-top: 20px;">
          <el-col :span="20">
            <el-form-item label="跳转页面地址">
              <el-input v-model="EditItem.outurl" placeholder="外链请使用https"></el-input>
              <div style="line-height: 16px; font-size: 12px; color: #046A81;">
                常用小程序页面地址：<br />

                文章详情：/pages/article/article?id=xxx<br />

              </div>
            </el-form-item>

          </el-col>
        </el-row>
        <el-row style="padding-top: 0px;">
          <el-col :span="10">
            <el-form-item label="启用">
             <el-select v-model="EditItem.show">
              <el-option label="启用" :value="1"></el-option>
              <el-option label="禁用" :value="0"></el-option>
             </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="padding-top: 0px;">
          <el-col :span="10">
            <el-form-item label="排序号">
              <el-input v-model="EditItem.sort"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <el-row>
        <el-col :span="22" style="text-align: right;">
          <el-button type="primary" @click="saveForm">保存</el-button>
        </el-col>
      </el-row>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      EditItem: {
        show:1,
        image: "",
        status: true,
        id: 0,
        outurl: "",
        sort: 0
      },
      DataList: [],

    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$http.post("/api/mini_banner_list", {
        page: 1
      }).then(res => {
        this.DataList = res.data.data
      })
    },
    update(item) {
      this.dialogVisible = true
      item.status = item.status ? true : false
      this.EditItem = item;
    },
    uploadCover(e) {

      this.EditItem.image = e.src
    },
    add() {
      this.EditItem = {
        image: "",
        status: true,
        id: 0,
        outurl: "",
        sort: 0,
        show:1
      }
      this.dialogVisible = true
    },
    remove(id) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/mini_banner_delete", {
          id: id
        }).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功'
          });

          this.getList();
        })

      }).catch(() => {

      });
    },
    saveForm() {
      if (this.EditItem.id > 0) {
        this.$http.post("/api/mini_banner_edit", {
          id: this.EditItem.id,
          image: this.EditItem.image,
          outurl: this.EditItem.outurl,
          sort: this.EditItem.sort,
          status: this.EditItem.status ? 1 : 0,
          show:this.EditItem.show
        }).then(res => {
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          this.dialogVisible = false
          this.getList();
        })
      } else {

        this.$http.post("/api/mini_banner_edit", {
          image: this.EditItem.image,
          outurl: this.EditItem.outurl,
          sort: this.EditItem.sort,
          status: this.EditItem.status ? 1 : 0
        }).then(res => {
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          this.dialogVisible = false
          this.getList();
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
